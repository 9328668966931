import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./component/Login/Login";
import Forget from "./component/Forget/Forget";
import Otp from "./component/Forget/Otp";
import Newpassword from "./component/Forget/Newpassword";
import Dashboard from "./component/Dashboard/Dashboard";
import Dashboard1 from "./component/Dashboard1/Dashboard1";
import Courses from "./component/Courses/Courses";
import CourseDetail from "./component/Courses/CourseDetail";
import Statistic from "./component/Courses/Statistic";
import Students from "./component/Courses/Students";
import Quiz from "./component/Quizes/Quiz";
import Addnewquiz from "./component/Quizes/Addnewquiz";
import Addquiz from "./component/Quizes/Addquiz";
import Addquestion from "./component/Quizes/Addquestion";
import Quizperview from "./component/Quizes/Quizperview";
import Quizgrade from "./component/Quizes/Quizgrade";
import Assignments from "./component/Assignments/Assignments";
import Assignmentcheck from "./component/Assignments/Assignmentcheck";
import Studentportfolio from "./component/StudentPortfolio/Studentportfolio";
import Editquestion from"../src/component/Quizes/Editquestion";


function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/forget" element={<Forget />} />
          <Route path="/forget/otp" element={<Otp />} />
          <Route path="/forget/otp/newpassword" element={<Newpassword />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/dashboard1" element={<Dashboard1 />} />
          <Route path="/courses" element={<Courses />} />
          <Route path="/coursedetail/:courseId" element={<CourseDetail />} />
          <Route path="/statistic/:courseId" element={<Statistic />} />
          <Route path="/students/:courseId" element={<Students />} />
          <Route path="/quiz" element={<Quiz />} />
          <Route path="/addnewquiz/:courseId" element={<Addnewquiz />} />
          <Route path="/addquiz/:courseId" element={<Addquiz />} />
          <Route path="/addquestion/:quizId" element={<Addquestion />} />
          <Route path="/editquestion/:courseId" element={<Editquestion />} />
          <Route path="/addperview/:quizId" element={<Quizperview />} />
          <Route path="/quizgrade/:quizId" element={<Quizgrade />} />
          <Route path="/assignment" element={<Assignments />} />
          <Route path="/assignmentcheck/:courseId" element={<Assignmentcheck />} />
          <Route path="/studentportfolio" element={<Studentportfolio />} />
        </Routes>

      </BrowserRouter>
    </div>
  );
}

export default App;
