import { useState, useEffect } from "react";
import React from 'react'
import "./login.css";
import LOGO from "../assets/LOGO.png";
import { Link, useNavigate } from "react-router-dom";

export default function Login() {
  const empty = {
    email: "",
    password: "",
  };
  const [handle, setHandle] = useState(empty);
  const handler = (e) => {
    const { name, value } = e.target;
    setHandle({ ...handle, [name]: value });
    console.log(handle);
  };
  const navigate = useNavigate();

  const logIn = async () => {
    if (handle.email === "" || handle.password === "") {
      alert("Please Enter the Username & Password");
    } else {
      try {
        const response = await fetch("https://api.mastertattooinstitute.com/api/v1/auth/login", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(handle),
        });

        if (!response.ok) {
          const errorText = await response.text();
          console.error(`Error ${response.status}: ${errorText}`);
          throw new Error("Authentication failed");
        }

        const data = await response.json();
        console.log(data)
        const { token, user } = data;

        if (token && user.role == 'instructor') {
          localStorage.setItem("token", token);
          localStorage.setItem("info", user.firstName);
          localStorage.setItem("info1", user.id);
          navigate("/dashboard");

        } else {
          alert('Authentication failed.');
        }
      } catch (error) {
        console.error("Authentication error:", error);
        alert("Authentication failed. Please check your credentials.");
      }


    }
  };

  return (
    <>
      <div className="background-image1">
        <div className="space-logo">
          <img
            src={LOGO}
            style={{ marginTop: "10px", marginLeft: "20px", height: "50px", marginBottom: '10px' }}
            alt="logo"
          />
          <h1 className="logo-des">MTI Instructor Portal</h1>
        </div>
        <hr className="h"></hr>
        <div className="di">
          <h1 className="title">LOGIN FORM </h1>
          <form onSubmit={(e) => {
            e.preventDefault();
            logIn();
          }}>
            <div className="email-center">
              <input
                className="email icon"
                onChange={handler}
                name="email"
                placeholder="Username/E-mail Address"
                type="email"
              />
            </div>
            <br></br>
            <div className="email-center">
              <input
                type="password"
                className="email icon1"
                style={{ marginTop: "14px" }}
                onChange={handler}
                name="password"
                placeholder="Password"
              />
            </div>
            <div className="for">
              <p>
                Forget Password ? &nbsp;
                <Link to="/forget" style={{ color: "#FF912C" }}>
                  Click Here
                </Link>
              </p>
            </div>
            <input type="submit" className="submit" value={"LOGIN"} />
          </form>

        </div>
        <br /><br /><br /><br />
        <p style={{ marginTop: '22px' }}></p>
      </div>
    </>
  );
}