import React from 'react';
import Profilepic from "../assets/Ellipse 3.svg";
import LOGO from "../assets/logo2.png";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faBell, faArrowRightFromBracket, faHome, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faTableColumns } from "@fortawesome/free-solid-svg-icons";
import { faInbox } from "@fortawesome/free-solid-svg-icons";
import { faClipboard } from "@fortawesome/free-solid-svg-icons";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import IMG from "../assets/Frame 3669.png";
import "./nav.css";

export default function Upernav() {
  const name = localStorage.getItem("info")
  return (
    <div>
      <div>
        <div className="data-flec">
          <div className="name">
            <h2 className="welcome-name">
              Welcome, {name}
              <span className="description">
                Lorem Ipsum sit amet dolor metier consecture{" "}
              </span>
            </h2>
          </div>
          <div className="icons-flex">
            <div className="icons">
              <div className="drop-down">
                <div class="btn-group" style={{ cursor: 'pointer', marginLeft: '15px' }}>
                  
                  <a data-bs-toggle="dropdown" aria-expanded="false">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="18" viewBox="0 0 24 18" fill="none" className='insidetext'>
                    <path d="M2.76923 0C1.24062 0 0 1.344 0 3V15C0 16.656 1.24062 18 2.76923 18H21.2308C22.7594 18 24 16.656 24 15V3C24 1.344 22.7594 0 21.2308 0H2.76923ZM2.76923 2H21.2308C21.7394 2 22.1538 2.449 22.1538 3V3.5L12 9.438L1.84615 3.5V3C1.84615 2.449 2.26062 2 2.76923 2ZM1.84615 3.781L7.87477 8.875L1.96154 15.438L9.17354 9.875L12 11.844L14.8274 9.875L22.0385 15.438L16.1252 8.875L22.1538 3.781V15C22.1487 15.154 22.1091 15.3043 22.0385 15.438C21.8862 15.763 21.5898 16 21.2308 16H2.76923C2.41015 16 2.11385 15.764 1.96154 15.438C1.891 15.3046 1.85141 15.1536 1.84615 15V3.781Z" fill="#283C55" fill-opacity="0.7" />
                  </svg>
              </a>
              <ul class="dropdown-menu" style={{ margin: '-5px 0 0 0', padding: '0px 0px 0px 0px' }}>
                <li class="dropdown-item notification"> <FontAwesomeIcon
                  className="fa"
                  icon={faEnvelope}
                  style={{ color: "#283C55" }}
                /> Messages</li>
                <li><a class="dropdown-item" href="#">Another action</a></li>
                <li><a class="dropdown-item" href="#">Something else here</a></li>
                <li><hr class="dropdown-divider" /></li>
                <li><a class="dropdown-item viewAll" href="#" style={{ textAlign: 'center' }}>ViewAll</a></li>
              </ul>
                </div>
              </div>
            </div>
            <div class="btn-group" style={{ cursor: 'pointer', marginLeft: '15px' }}>
              <a data-bs-toggle="dropdown" aria-expanded="false">
                <svg
                  class=""
                  href="#"
                  xmlns="http://www.w3.org/2000/svg"
                  width="39"
                  height="38"
                  viewBox="0 0 39 38"
                  fill="none"
                >
                  <path
                    d="M30.1769 21.2334L28.0082 19.0647V16.1918C28.0058 14.2064 27.2672 12.2925 25.9354 10.8201C24.6036 9.34776 22.7731 8.42149 20.798 8.22046V6.57812H19.1957V8.22046C17.2206 8.42149 15.3901 9.34776 14.0583 10.8201C12.7265 12.2925 11.9879 14.2064 11.9855 16.1918V19.0647L9.81677 21.2334C9.66651 21.3836 9.58208 21.5873 9.58203 21.7998V24.2032C9.58203 24.4157 9.66644 24.6194 9.81668 24.7697C9.96692 24.9199 10.1707 25.0043 10.3832 25.0043H15.9911V25.6268C15.9737 26.6432 16.332 27.6302 16.9972 28.3988C17.6625 29.1674 18.5879 29.6635 19.5963 29.7919C20.1532 29.8472 20.7155 29.7852 21.2471 29.6101C21.7787 29.4349 22.2677 29.1505 22.6827 28.775C23.0977 28.3995 23.4295 27.9413 23.6569 27.4299C23.8842 26.9185 24.0019 26.3651 24.0025 25.8055V25.0043H29.6105C29.823 25.0043 30.0268 24.9199 30.177 24.7697C30.3273 24.6194 30.4117 24.4157 30.4117 24.2032V21.7998C30.4116 21.5873 30.3272 21.3836 30.1769 21.2334ZM22.4003 25.8055C22.4003 26.4429 22.147 27.0542 21.6963 27.5049C21.2456 27.9557 20.6343 28.2089 19.9968 28.2089C19.3594 28.2089 18.7481 27.9557 18.2974 27.5049C17.8466 27.0542 17.5934 26.4429 17.5934 25.8055V25.0043H22.4003V25.8055ZM28.8094 23.4021H11.1843V22.1314L13.353 19.9628C13.5032 19.8126 13.5877 19.6088 13.5877 19.3964V16.1918C13.5877 14.492 14.263 12.8618 15.4649 11.6599C16.6669 10.4579 18.297 9.78268 19.9968 9.78268C21.6966 9.78268 23.3268 10.4579 24.5288 11.6599C25.7307 12.8618 26.406 14.492 26.406 16.1918V19.3964C26.406 19.6088 26.4904 19.8126 26.6407 19.9628L28.8094 22.1314V23.4021Z"
                    fill="#283C55"
                    fill-opacity="0.7"
                  />
                  <rect
                    x="1.08657"
                    y="0.274074"
                    width="36.7259"
                    height="36.7259"
                    rx="1.91852"
                    stroke="#283C55"
                    stroke-opacity="0.5"
                    stroke-width="0.548148"
                  />
                </svg>
              </a>
              <ul class="dropdown-menu"style={{transform: 'translate(0px, 30px)', margin: '-5px 0 0 0', padding: '0px 0px 0px 0px' }} >
                <li class="dropdown-item notification"> <FontAwesomeIcon
                  className="fa"
                  icon={faBell}
                  style={{ color: "#283C55" }}
                /> Notification</li>
                <li><a class="dropdown-item" href="#">Another action</a></li>
                <li><a class="dropdown-item" href="#">Something else here</a></li>
                <li><hr class="dropdown-divider" /></li>
                <li><a class="dropdown-item viewAll" href="#" style={{ textAlign: 'center' }}>ViewAll</a></li>
              </ul>
            </div>
            {/* <div className="search-bar">
              <Popup
                trigger={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="38"
                    height="38"
                    viewBox="0 0 38 38"
                    fill="none"
                  >
                    <path
                      d="M29.2294 27.4708L23.7172 21.9586C25.0419 20.3684 25.7024 18.3287 25.5614 16.2639C25.4205 14.199 24.4889 12.268 22.9605 10.8726C21.4321 9.47709 19.4245 8.72459 17.3554 8.77161C15.2863 8.81862 13.315 9.66153 11.8515 11.125C10.3881 12.5884 9.54519 14.5598 9.49817 16.6289C9.45116 18.698 10.2037 20.7055 11.5991 22.234C12.9946 23.7624 14.9256 24.6939 16.9904 24.8349C19.0553 24.9758 21.0949 24.3153 22.6852 22.9907L28.1974 28.5029L29.2294 27.4708ZM10.982 16.8245C10.982 15.5253 11.3673 14.2552 12.0891 13.1749C12.8109 12.0946 13.8368 11.2527 15.0372 10.7555C16.2375 10.2583 17.5584 10.1282 18.8326 10.3817C20.1069 10.6351 21.2774 11.2608 22.1961 12.1795C23.1148 13.0982 23.7405 14.2687 23.9939 15.5429C24.2474 16.8172 24.1173 18.138 23.6201 19.3384C23.1229 20.5387 22.2809 21.5647 21.2007 22.2865C20.1204 23.0083 18.8503 23.3936 17.5511 23.3936C15.8094 23.3917 14.1397 22.6989 12.9082 21.4674C11.6766 20.2359 10.9839 18.5661 10.982 16.8245Z"
                      fill="#283C55"
                      fill-opacity="0.7"
                    />
                    <rect
                      x="1.00064"
                      y="0.274074"
                      width="36.7259"
                      height="36.7259"
                      rx="1.91852"
                      stroke="#283C55"
                      stroke-opacity="0.5"
                      stroke-width="0.548148"
                    />
                  </svg>
                }
                modal
                nested
              >
                {(close) => (
                  <div className="popup-style">
                    <div>
                      <div>
                        <div className="search">
                          <i class="fa-solid fa-magnifying-glass sear"></i>
                          <input
                            type="search"
                            className="searchstyle"
                            placeholder="Search content, user, etc"
                          />
                          <i
                            class="fa-solid fa-xmark sear"
                            onClick={() => close()}
                            style={{ cursor: "pointer" }}
                          ></i>
                        </div>
                        <hr></hr>
                        <h2 className="recent-result">
                          Recent Result
                        </h2>
                        <div className="result">
                          <a href="" className="result1">
                            User
                          </a>
                          <a href="" className="result1">
                            Content
                          </a>
                          <a href="" className="result1">
                            Groups
                          </a>
                        </div>
                        <hr></hr>
                        <div className="srcoll">
                          <div className="cards1">
                            <img src={IMG} />
                            <p className="title">
                              Course Title -<br></br>
                              <span className="public">
                                <i class="fa-regular fa-eye"></i>
                                &nbsp;&nbsp;Public
                              </span>
                              <span className="public">
                                <i class="fa-regular fa-user"></i>
                                &nbsp;&nbsp;27 lectures
                              </span>
                              <span className="public">
                                <i class="fa-solid fa-paintbrush"></i>
                                &nbsp;&nbsp;Design
                              </span>
                            </p>
                          </div>
                          <br></br>
                          <div className="cards1">
                            <img src={IMG} />
                            <p className="title">
                              Course Title -<br></br>
                              <span className="public">
                                <i class="fa-regular fa-eye"></i>
                                &nbsp;&nbsp;Public
                              </span>
                              <span className="public">
                                <i class="fa-regular fa-user"></i>
                                &nbsp;&nbsp;27 lectures
                              </span>
                              <span className="public">
                                <i class="fa-solid fa-paintbrush"></i>
                                &nbsp;&nbsp;Design
                              </span>
                            </p>
                          </div>
                          <br></br>
                          <div className="cards1">
                            <img src={IMG} />
                            <p className="title">
                              Course Title -<br></br>
                              <span className="public">
                                <i class="fa-regular fa-eye"></i>
                                &nbsp;&nbsp;Public
                              </span>
                              <span className="public">
                                <i class="fa-regular fa-user"></i>
                                &nbsp;&nbsp;27 lectures
                              </span>
                              <span className="public">
                                <i class="fa-solid fa-paintbrush"></i>
                                &nbsp;&nbsp;Design
                              </span>
                            </p>
                          </div>
                          <br></br>
                          <div className="cards1">
                            <img src={IMG} />
                            <p className="title">
                              Course Title -<br></br>
                              <span className="public">
                                <i class="fa-regular fa-eye"></i>
                                &nbsp;&nbsp;Public
                              </span>
                              <span className="public">
                                <i class="fa-regular fa-user"></i>
                                &nbsp;&nbsp;27 lectures
                              </span>
                              <span className="public">
                                <i class="fa-solid fa-paintbrush"></i>
                                &nbsp;&nbsp;Design
                              </span>
                            </p>
                          </div>
                          <br></br>
                          <div className="cards1">
                            <img src={IMG} />
                            <p className="title">
                              Course Title -<br></br>
                              <span className="public">
                                <i class="fa-regular fa-eye"></i>
                                &nbsp;&nbsp;Public
                              </span>
                              <span className="public">
                                <i class="fa-regular fa-user"></i>
                                &nbsp;&nbsp;27 lectures
                              </span>
                              <span className="public">
                                <i class="fa-solid fa-paintbrush"></i>
                                &nbsp;&nbsp;Design
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </Popup>
            </div> */}
            <p className="line">&nbsp;</p>
            <div className="signup">
              <div className="sign-img">
                <img
                  src={Profilepic}
                  className="profilepic"
                  alt="Profile-Pic"
                />
              </div>
              <div className="drop-down">
                <div class="dropdown">
                  <a
                    class=" dropdown-toggle admin-name"
                    href="#"
                    role="button"
                    id="dropdownMenuLink"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {name}
                  </a>
                  <span className="admin-check">Instructor</span>

                  <ul
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuLink"

                  >
                    <li>
                      <Link to="/dashboard" class="dropdown-item">
                        <FontAwesomeIcon
                          icon={faHome}
                          style={{ color: "#283C55" }}
                        />
                        &nbsp;Home
                      </Link>
                    </li>
                    <li>
                      <Link to="/dashboard1" class="dropdown-item">
                        <FontAwesomeIcon
                          icon={faTableColumns}
                          style={{ color: "#283C55" }}
                        />
                        &nbsp;Dashboard
                      </Link>
                    </li>
                    <li>
                      <Link to="/courses" class="dropdown-item">
                        <FontAwesomeIcon
                          icon={faClipboard}
                          style={{ color: "#283C55" }}
                        />
                        &nbsp;Content
                      </Link>
                    </li>
                    <li>
                      <Link to="/studentportfolio" class="dropdown-item">
                        <FontAwesomeIcon
                          icon={faSearch}
                          style={{ color: "#283C55" }}
                        />
                        &nbsp;Explore Student Portfolio
                      </Link>
                    </li>

                    <li>
                      <Link class="dropdown-item" to="/"
                        onClick={() => {
                          localStorage.removeItem('token')
                          localStorage.removeItem('prifilepicture');
                          localStorage.removeItem('Fullname');
                          localStorage.removeItem('email1');
                          localStorage.removeItem('info');
                          localStorage.removeItem('info1');
                          localStorage.removeItem('username');

                        }}
                      >
                        <FontAwesomeIcon
                          className="fa"
                          icon={faArrowRightFromBracket}
                          style={{ color: "#283C55" }}
                        />
                        &nbsp;SignOut
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </div>
        </div>
      </div>
    </div>
  )
}
