import React, { useState, useEffect } from "react";
import "./Cdetail.css";
import Profilepic from "../assets/Ellipse 3.svg";
import LOGO from "../assets/logo2.png";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import IMG from "../assets/Frame 3669.png";
import LOG from "../assets/Frame 3720.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faHome } from "@fortawesome/free-solid-svg-icons";
import { faTableColumns } from "@fortawesome/free-solid-svg-icons";
import { faInbox } from "@fortawesome/free-solid-svg-icons";
import { faClipboard } from "@fortawesome/free-solid-svg-icons";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import { Link, useParams, useNavigate } from "react-router-dom";
import Group from "../assets/Group 26.png";
import Upernav from "../Upernav/Upernav";
import axios from "axios";


export default function CourseDetail() {

  const [isUlVisible, setIsUlVisible] = useState(false);
  const toggleUlVisibility = () => {
    setIsUlVisible(!isUlVisible);
  };
  const [isMenuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };
  const { courseId } = useParams();
  console.log(courseId);


  const [cover, setCover] = useState(null);
  let [selectedWeek, setSelectedWeek] = useState(0);
  const [courseDate, setcourseDate] = useState('');
  const [weekength, setWeekLenght] = useState('');
  const [weekid, setWeek1] = useState('');
  const [newweek, setnewWeek] = useState('');
  const [weekName, setName] = useState('');
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    // Function to make the API request
    const Token = localStorage.getItem("token")
    const fetchData = async () => {
      try {
        // Replace 'your-api-endpoint' with the actual API endpoint
        const response = await axios.get(`https://195.35.37.213:1337/api/v1/course/${courseId}`, {
          headers: {
            Authorization: `Bearer ${Token}`,
          },
        });

        // Set the fetched data in the state
        // console.log(response.data.length)
        setcourseDate(response.data.createdAt);
        setWeekLenght(response.data.weeks.length);
        setnewWeek(response.data.weeks);
        setWeek1(response.data.weeks);
        setCover(response.data.cover.fileName);
        setLoading(false);


      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    // Call the fetchData function
    fetchData();
  }, [selectedWeek]);

  const weeks = Array.from({ length: weekength }, (_, index) => index);
  console.log(weeks)
  const [inks, setInks] = useState('');
  const [material, setMaterial] = useState('');
  const [needles, setNeedles] = useState('');
  const [weekdiscription, setDescription] = useState('');
  const [updated, setUpdated] = useState('');
  const [recording, setrecording] = useState('')
  const [lectureTitle, setlectureTitle] = useState('');
  const [lecid, setlecid] = useState('');
  const [setLec, setnewlec] = useState('');
  const [WeekId1, setWeekid1] = useState('');
  useEffect(() => {
    const Token = localStorage.getItem("token");
    const fetchData1 = async () => {
      try {
        const response = await axios.get(`https://api.mastertattooinstitute.com/api/v1/course/${courseId}`, {
          headers: {
            Authorization: `Bearer ${Token}`,
          },
        });
        setInks(response.data.weeks[0].lectures[0].inks);
        setDescription(response.data.weeks[0].lectures[0].description);
        setMaterial(response.data.weeks[0].lectures[0].standardMethods);
        setNeedles(response.data.weeks[0].lectures[0].needles);
        setUpdated(response.data.weeks[0].lectures[0].createdAt);
        setlectureTitle(response.data.weeks[0].lectures[0].data.name);
        setLoading(false);
        const lectureIdl = response.data.weeks[0].lectures[0].id;
        fetch(`https://api.mastertattooinstitute.com/api/v1/lecture/${lectureIdl}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${Token}`,
          },
        })
          .then(response => response.json())
          .then(data => {
            setrecording(data.recording);
            console.log(data);
            setLoading(false);
          })
          .catch(error => {
            console.error('Error fetching lecture data:', error);
            setLoading(false);
          });
      } catch (error) {
        console.error('Error fetching course data:', error);
        setLoading(false);
      }
    };
    // Call the fetchData function
    fetchData1();
  }, []); // Include dependencies in the array if needed
  const handleWeekClick = (week) => {
    setSelectedWeek(week);

    // Function to make the API request

    const Token = localStorage.getItem("token")
    fetch(`https://api.mastertattooinstitute.com/api/v1/week/${newweek[week].id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${Token}`,
      },

    })
      .then(response => response.json())
      .then(data => {

        fetch(`https://api.mastertattooinstitute.com/api/v1/lecture/${data.lectures[0].id}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${Token}`,
          },

        })
          .then(response => response.json())
          .then(data => {
            setInks(data.inks);
            setDescription(data.description);
            setMaterial(data.standardMethods);
            setNeedles(data.needles);
            setUpdated(data.createdAt);
            setrecording(data.recording);
            setlectureTitle(data.name);
            setLoading(false);
          })
          .catch(error => {
            console.error('Error fetching data:', error);
            setLoading(false);
          });
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });





  };



  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString(); // Adjust based on your formatting needs
  };

  return (
    <div className="body-background">
      {loading ? (
        <div className="kkw">
          <span class="loaders"></span>
        </div>
      ) : (
        <div className="full-section">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-3 col-md-3 col-sm-12">
                <div className="fixer">
                  <div className="data">
                    <img src={LOGO} />
                  </div>
                  <div className="leftnav">
                    <button onClick={toggleUlVisibility}>
                      <FontAwesomeIcon
                        className="bars"
                        icon={faBars}
                        style={{ color: "#283C55" }}
                      />
                    </button>
                    <ul
                      className="leftnav-1 padder1"
                      style={{ display: isUlVisible ? "none" : "block" }}
                    >
                      <li>
                        <Link to="/dashboard" >
                          <FontAwesomeIcon
                            icon={faHome}
                            style={{ color: "#283C55", marginBottom: "10px" }}
                          />
                          <h2>Home</h2>
                        </Link>
                      </li>
                      <li>
                        <Link to="/dashboard1">
                          <FontAwesomeIcon
                            icon={faTableColumns}
                            style={{ color: "#283C55", marginBottom: "10px" }}
                          />
                          <h2>Dashboard</h2>
                        </Link>
                      </li>
                      <li>
                        <a to="">
                          <FontAwesomeIcon
                            icon={faInbox}
                            style={{ color: "#283C55", marginBottom: "10px" }}
                          />
                          <h2>Inbox</h2>
                        </a>
                      </li>
                      <hr />
                      <li>
                        <div className="isactive" style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <Link to="/courses">
                            <FontAwesomeIcon
                              icon={faClipboard}
                              style={{ color: "#283C55", marginBottom: "10px" }}
                            />
                            <h2>Content</h2>
                          </Link>
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="7" viewBox="0 0 16 7" fill="none" style={{ marginTop: '7px' }}>
                            <path d="M15 1L8 6L1 1" stroke="#283C55" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>
                        </div>
                        <ul className="sub-tabs-list" style={{ padding: '0' }}>
                          <li className="sub-tab isactive1">
                            <Link to="/courses">Courses</Link>
                          </li>
                          <li className="sub-tab">
                            <Link to="/quiz">Quiz</Link>
                          </li>
                          <li className="sub-tab">
                            <Link to="/assignment">Assignments</Link>
                          </li>

                        </ul>
                      </li>
                      <li>
                        <Link to="/studentportfolio">
                          <FontAwesomeIcon
                            icon={faSearch}
                            style={{ color: "#283C55" }}
                          />
                          <h2>Explore Student Portfolio</h2>
                        </Link>
                      </li>
                      <li>

                      </li>
                    </ul>
                    <ul
                      className="leftnav-2 padder1"
                      style={{ display: isUlVisible ? "none" : "block", marginTop: '-80px' }}
                    >
                      <li>
                        <a href="">
                          <FontAwesomeIcon
                            icon={faCircleInfo}
                            style={{ color: "#283C55" }}
                          />
                          <h2>Help</h2>
                        </a>
                      </li>
                      <li>
                        <a href="">
                          <FontAwesomeIcon
                            icon={faGear}
                            style={{ color: "#283C55" }}
                          />
                          <h2>Settings</h2>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-9 col-md-9 col-sm-12">
                <div className="div-style">
                  <div className="container">
                    <Upernav />
                  </div>
                  <div className="container-fuild">
                    <p style={{ borderBottom: '1px solid rgba(40, 60, 85, 0.40)' }}></p>
                  </div>
                  <div className="container">
                    <Link to={'/courses'} className="back-course">
                      <img src={Group} />
                      &nbsp; Back to Courses
                    </Link>
                  </div>
                  <div className="container back">
                    {cover ? (
                      // Render the profile picture if fileName is not null
                      <img src={'https://api.mastertattooinstitute.com/' + cover} alt="Profile" className="banner-img" />
                    ) : (
                      // Render another picture or a default image when fileName is null
                      <img src={LOG} alt="" className="banner-img" />
                    )}{" "}
                    <p className="course-t">Weeks Content </p>
                    {/* <div className="perview">
                  <div className="public">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22"
                      height="21"
                      viewBox="0 0 22 21"
                      fill="none"
                    >
                      <path
                        d="M3.97521 8.14271H18.2654M3.97521 13.2464H18.2654M3.46484 10.6945C3.46484 11.6999 3.66286 12.6954 4.04758 13.6242C4.43231 14.553 4.9962 15.3969 5.70708 16.1078C6.41796 16.8187 7.26189 17.3826 8.1907 17.7673C9.1195 18.152 10.115 18.35 11.1203 18.35C12.1257 18.35 13.1211 18.152 14.0499 17.7673C14.9787 17.3826 15.8227 16.8187 16.5336 16.1078C17.2444 15.3969 17.8083 14.553 18.1931 13.6242C18.5778 12.6954 18.7758 11.6999 18.7758 10.6945C18.7758 8.66418 17.9692 6.71698 16.5336 5.2813C15.0979 3.84562 13.1507 3.03906 11.1203 3.03906C9.08996 3.03906 7.14276 3.84562 5.70708 5.2813C4.2714 6.71698 3.46484 8.66418 3.46484 10.6945Z"
                        stroke="#283C55"
                        stroke-width="1.70122"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M10.6966 3.03906C9.26361 5.33537 8.50391 7.98779 8.50391 10.6945C8.50391 13.4013 9.26361 16.0537 10.6966 18.35M11.5472 3.03906C12.9802 5.33537 13.7399 7.98779 13.7399 10.6945C13.7399 13.4013 12.9802 16.0537 11.5472 18.35"
                        stroke="#283C55"
                        stroke-width="1.70122"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <p className="published">Published</p>
                  </div>
                  <div className="public">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22"
                      height="23"
                      viewBox="0 0 22 23"
                      fill="none"
                    >
                      <path
                        d="M6.29688 10.5547H8.08995V12.3478H6.29688V10.5547ZM6.29688 14.1408H8.08995V15.9339H6.29688V14.1408ZM9.88302 10.5547H11.6761V12.3478H9.88302V10.5547ZM9.88302 14.1408H11.6761V15.9339H9.88302V14.1408ZM13.4692 10.5547H15.2622V12.3478H13.4692V10.5547ZM13.4692 14.1408H15.2622V15.9339H13.4692V14.1408Z"
                        fill="#283C55"
                        fill-opacity="0.6"
                      />
                      <path
                        d="M4.50011 20.4151H17.0516C18.0405 20.4151 18.8447 19.6109 18.8447 18.622V6.07052C18.8447 5.08164 18.0405 4.27745 17.0516 4.27745H15.2586V2.48438H13.4655V4.27745H8.08625V2.48438H6.29318V4.27745H4.50011C3.51123 4.27745 2.70703 5.08164 2.70703 6.07052V18.622C2.70703 19.6109 3.51123 20.4151 4.50011 20.4151ZM17.0516 7.8636L17.0525 18.622H4.50011V7.8636H17.0516Z"
                        fill="#283C55"
                        fill-opacity="0.6"
                      />
                    </svg>
                    <p className="published">Created on {formatDate(courseDate)}</p>
                  </div>
                  <div className="public">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="19"
                      viewBox="0 0 18 19"
                      fill="none"
                    >
                      <g clip-path="url(#clip0_719_6782)">
                        <path
                          d="M8.7895 7.28711C8.2159 7.28711 7.66579 7.51497 7.2602 7.92057C6.8546 8.32617 6.62674 8.87627 6.62674 9.44987C6.62674 10.0235 6.8546 10.5736 7.2602 10.9792C7.66579 11.3848 8.2159 11.6126 8.7895 11.6126C9.3631 11.6126 9.91321 11.3848 10.3188 10.9792C10.7244 10.5736 10.9523 10.0235 10.9523 9.44987C10.9523 8.87627 10.7244 8.32617 10.3188 7.92057C9.91321 7.51497 9.3631 7.28711 8.7895 7.28711ZM8.7895 13.0545C7.8335 13.0545 6.91666 12.6747 6.24066 11.9987C5.56467 11.3227 5.1849 10.4059 5.1849 9.44987C5.1849 8.49387 5.56467 7.57703 6.24066 6.90103C6.91666 6.22504 7.8335 5.84527 8.7895 5.84527C9.7455 5.84527 10.6623 6.22504 11.3383 6.90103C12.0143 7.57703 12.3941 8.49387 12.3941 9.44987C12.3941 10.4059 12.0143 11.3227 11.3383 11.9987C10.6623 12.6747 9.7455 13.0545 8.7895 13.0545ZM8.7895 4.04297C5.1849 4.04297 2.10657 6.28503 0.859375 9.44987C2.10657 12.6147 5.1849 14.8568 8.7895 14.8568C12.3941 14.8568 15.4724 12.6147 16.7196 9.44987C15.4724 6.28503 12.3941 4.04297 8.7895 4.04297Z"
                          fill="#283C55"
                          fill-opacity="0.6"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_719_6782">
                          <rect
                            width="17.3021"
                            height="17.3021"
                            fill="white"
                            transform="translate(0.136719 0.796875)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                    <p className="published">Public</p>
                  </div>
                </div> */}
                    <div className="nav-perview">
                      <Link to={`/coursedetail/${courseId}`} className="con con-is">
                        Content
                      </Link>
                      <Link to={`/statistic/${courseId}`} className="con">
                        Statistics
                      </Link>
                      <Link to={`/students/${courseId}`} className="con">
                        Students
                      </Link>
                    </div>

                    <div className="week-content">
                      <div className="row">
                        <div className="col-lg-4 col-md-12 col-sm-12">
                          <div className="week-list">
                            <ul style={{ padding: '0' }}>
                              {weeks.map((week) => (
                                <li
                                  key={week}
                                  className={week === selectedWeek ? "active-week" : ""}
                                  onClick={() => handleWeekClick(week)}
                                >

                                  <h2>Week - {week + 1}</h2>
                                  <p>Updated on{formatDate(updated)}</p>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                        <div className="col-lg-8 col-md-12 col-sm-12">
                          <div className="week">
                            <h3>Week {selectedWeek + 1} Content</h3>
                            <div className="row">
                              <div className="col-lg-7 col-md-7 col-sm-12">
                                <div className="items-required">
                                  <div className="items-required-title">
                                    <h2>Items Required</h2>
                                  </div>
                                  <div className="items-required-text">
                                    <h2>Inks:{inks}</h2>
                                  </div>
                                  <div className="items-required-text">
                                    <h2>Needles:{needles}</h2>
                                  </div>
                                  <div className="items-required-text">
                                    <h2>Standard Material:{material}</h2>
                                  </div>
                                </div>
                                <div className="lecture-design">
                                  <h2 className="segment-title">
                                    {lectureTitle}

                                  </h2>
                                  <div className="segment-video">
                                    {/* <iframe
                                  src="https://www.youtube.com/embed/h9xaHwBsRUw"
                                  title="YouTube Video"
                                  frameborder="0"
                                ></iframe> */}
                                    {/* <img
                                src={'http://195.35.37.213:1337/'+recording}
                                className="upload-image"
                                style={{ width: "100%" }}
                              /> */}
                                    {recording?.fileName ? (
                                      // Render the profile picture if fileName is not null
                                      <video controls width="400">
                                        <source src={'https://api.mastertattooinstitute.com/' + recording.fileName} type="video/mp4" />
                                        Your browser does not support the video tag.
                                      </video>
                                    ) : (
                                      // Render another picture or a default image when fileName is null
                                      <img src={IMG} className="no_img" alt="Default" />
                                    )}{" "}
                                  </div>
                                </div>
                                <p className="class-des">
                                  {weekdiscription}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <br />
              </div>
            </div>

          </div>
          <br />
        </div>





      )}
    </div>
  )
}
