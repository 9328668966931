import React, { useState, useEffect } from 'react';
import "./dashboard1.css";
import LOGO from "../assets/logo2.png";
import Sidenav from "../Upernav/Sidenav";
import Upernav from "../Upernav/Upernav";
import Frame from "../assets/Frame 3619.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faHome } from "@fortawesome/free-solid-svg-icons";
import { faTableColumns } from "@fortawesome/free-solid-svg-icons";
import { faInbox } from "@fortawesome/free-solid-svg-icons";
import { faClipboard } from "@fortawesome/free-solid-svg-icons";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from 'react-router-dom';

export default function Dashboard1() {
  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
    }
  }, []);
  const [courses, setcourse] = useState([]);
  const id = localStorage.getItem("info1");
  const [loading, setLoading] = useState(true);
  // console.log(id);



  useEffect(() => {
    const token1 = localStorage.getItem("token");
    fetch(`https://api.mastertattooinstitute.com/api/v1/course/instructor/${id}/fetch`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token1}`,

      },
    }).then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    })
      .then((data) => {
        setcourse(data);
        console.log(data)
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  }, [id]);

  const extractFirst22Words = (paragraph) => {
    return paragraph.slice(0, 17);
  };


  const [isUlVisible, setIsUlVisible] = useState(false);
  const toggleUlVisibility = () => {
    setIsUlVisible(!isUlVisible);
  };
  const [isMenuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };
  return (
    <div className="body-background">
      {loading ? (
        <div className="kkw">
          <span class="loaders"></span>
        </div>
      ) : (
        <div className="full-section">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-3 col-md-3 col-sm-12">
                <div className="fixer1">
                  <div className="data">
                    <img src={LOGO} />
                  </div>
                  <div className="leftnav">
                    <button onClick={toggleUlVisibility}>
                      <FontAwesomeIcon
                        className="bars"
                        icon={faBars}
                        style={{ color: "#283C55" }}
                      />
                    </button>
                    <ul
                      className="leftnav-1 padder1"
                      style={{ display: isUlVisible ? "none" : "block" }}
                    >
                      <li>
                        <Link to="/dashboard" >
                          <FontAwesomeIcon
                            icon={faHome}
                            style={{ color: "#283C55", marginBottom: "10px" }}
                          />
                          <h2>Home</h2>
                        </Link>
                      </li>
                      <li>
                        <Link to="/dashboard1" className="isactive">
                          <FontAwesomeIcon
                            icon={faTableColumns}
                            style={{ color: "#283C55", marginBottom: "10px" }}
                          />
                          <h2>Dashboard</h2>
                        </Link>
                      </li>
                      <li>
                        <a href="#">
                          <FontAwesomeIcon
                            icon={faInbox}
                            style={{ color: "#283C55", marginBottom: "10px" }}
                          />
                          <h2>Inbox</h2>
                        </a>
                      </li>
                      <hr />
                      <li>
                        <Link to="/courses">
                          <FontAwesomeIcon
                            icon={faClipboard}
                            style={{ color: "#283C55", marginBottom: "10px" }}
                          />
                          <h2>Content</h2>
                        </Link>
                      </li>
                      <li>
                        <Link to="/studentportfolio">
                          <FontAwesomeIcon
                            icon={faSearch}
                            style={{ color: "#283C55" }}
                          />
                          <h2>Explore Student Portfolio</h2>
                        </Link>
                      </li>

                    </ul>
                    <ul
                      className="leftnav-2 padder1"
                      style={{ display: isUlVisible ? "none" : "block" }}
                    >
                      <li>
                        <a href="">
                          <FontAwesomeIcon
                            icon={faCircleInfo}
                            style={{ color: "#283C55" }}
                          />
                          <h2>Help</h2>
                        </a>
                      </li>
                      <li>
                        <a href="">
                          <FontAwesomeIcon
                            icon={faGear}
                            style={{ color: "#283C55" }}
                          />
                          <h2>Settings</h2>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-9 col-md-9 col-sm-12">
                <div className="div-style">
                  <div className="container">
                    <Upernav />
                  </div>
                  <div className="container-fuild">
                    <p style={{ borderBottom: '1px solid rgba(40, 60, 85, 0.40)' }}></p>
                  </div>
                  <br />
                  <div className="container">

                    <h2 className='your-statistic'>Your Statistic</h2>
                  </div>
                  <br />
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-3 col-mb-6 col-sm-12">
                        <div className="total-courses">
                          <h2 className='num-course'>05 <span className='t-course'>Total Courses</span></h2>
                        </div>
                      </div>
                      <div className="col-lg-3 col-mb-6 col-sm-12">
                        <div className="total-courses1">
                          <h2 className='num-course'>3 Hours <span className='t-course'>Learning in this week</span></h2>
                        </div>
                      </div>
                      <div className="col-lg-3 col-mb-6 col-sm-12">
                        <div className="total-courses2">
                          <h2 className='num-course'>02<span className='t-course'>Courses Completed</span></h2>
                        </div>
                      </div>
                      <div className="col-lg-3 col-mb-6 col-sm-12">
                        <div className="total-courses3">
                          <h2 className='num-course'>02<span className='t-course'>Assignment Completed</span></h2>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="container">
                    <h2 className='your-statistic1'>Assigned Courses</h2>
                  </div>
                  <div className="container cnn">
                    <div className="row">
                      {courses?.map((item, index) => (
                        <div className="col-lg-4 col-md-6 col-sm-12">
                          <div class="card" style={{ width: '17rem' }}>
                            <img class="card-img-top" src={Frame} alt="Card image cap" />
                            <div class="card-body">
                              <h5 class="card-title">Course Title - {item.title}</h5>
                              <p class="card-text">{extractFirst22Words(item.description)}...</p>
                              <div className="" style={{ display: 'flex', justifyContent: 'center' }}>
                                <a href="#" class="btn btnnn1">Continue</a>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <br></br>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
