import React,{useState} from 'react'
import "./nav.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faHome } from "@fortawesome/free-solid-svg-icons";
import { faTableColumns } from "@fortawesome/free-solid-svg-icons";
import { faInbox } from "@fortawesome/free-solid-svg-icons";
import { faClipboard } from "@fortawesome/free-solid-svg-icons";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

export default function Sidenav() {
  
    const [isUlVisible, setIsUlVisible] = useState(false);
  const toggleUlVisibility = () => {
    setIsUlVisible(!isUlVisible);
  };
  const [isMenuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };
  return (
    <div>
             <div className="leftnav">
                <button onClick={toggleUlVisibility}>
                  <FontAwesomeIcon
                    className="bars"
                    icon={faBars}
                    style={{ color: "#283C55" }}
                  />
                </button>
                <ul
                  className="leftnav-1 padder1"
                  style={{ display: isUlVisible ? "none" : "block" }}
                >
                  <li>
                    <Link to="/dashboard" className="isactive">
                      <FontAwesomeIcon
                        icon={faHome}
                        style={{ color: "#283C55", marginBottom: "10px" }}
                      />
                      <h2>Home</h2>
                    </Link>
                  </li>
                  <li>
                    <Link to="/dashboard1">
                      <FontAwesomeIcon
                        icon={faTableColumns}
                        style={{ color: "#283C55", marginBottom: "10px" }}
                      />
                      <h2>Dashboard</h2>
                    </Link>
                  </li>
                  <li>
                    <a href="#">
                      <FontAwesomeIcon
                        icon={faInbox}
                        style={{ color: "#283C55", marginBottom: "10px" }}
                      />
                      <h2>Inbox</h2>
                    </a>
                  </li>
                  <hr />
                  <li>
                    <Link to="/courses">
                      <FontAwesomeIcon
                        icon={faClipboard}
                        style={{ color: "#283C55", marginBottom: "10px" }}
                      />
                      <h2>Content</h2>
                    </Link>
                  </li>
                  <li>
                    <Link to="/studentportfolio">
                      <FontAwesomeIcon
                        icon={faSearch}
                        style={{ color: "#283C55" }}
                      />
                      <h2>Explore Student Portfolio</h2>
                    </Link>
                  </li>
                  
                </ul>
                <ul
                  className="leftnav-2 padder1"
                  style={{ display: isUlVisible ? "none" : "block" }}
                >
                  <li>
                    <a href="">
                      <FontAwesomeIcon
                        icon={faCircleInfo}
                        style={{ color: "#283C55" }}
                      />
                      <h2>Help</h2>
                    </a>
                  </li>
                  <li>
                    <a href="">
                      <FontAwesomeIcon
                        icon={faGear}
                        style={{ color: "#283C55" }}
                      />
                      <h2>Settings</h2>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
    
  )
}
